import { useInterval } from 'ahooks';
import clsx from 'clsx';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PageWrapper } from '../components/PageWrapper';
import { useGlobalStore } from '../utils/store';

const loadingTexts = [
  '停下来，感受你的内心。',
  '三分钟，给问题一点时间。',
  '答案，藏在问题中间。',
  '感召，理性的指引。',
];

const durationIn = 2000;
const durationShow = 2000;
const durationOut = 1000;

export function BeforeOptionPage() {
  const navigate = useNavigate();

  const steps = useGlobalStore((state) => state.steps);
  const loaded = steps.length > 0;

  const [startAt] = useState(Date.now());
  const [tick, setTick] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [neverVisibleAgain, setNeverVisibleAgain] = useState(false);
  const [text, setText] = useState('');

  useInterval(() => {
    if (neverVisibleAgain) return;
    const timeElapsed = Date.now() - startAt;
    const phaseNum = Math.floor(timeElapsed / (durationIn + durationShow + durationOut));
    const phaseElapsed = timeElapsed % (durationIn + durationShow + durationOut);
    const index = phaseNum % loadingTexts.length;
    const inIndexOneAndAlreadyShow = index === 1 && phaseElapsed >= durationIn + durationShow;
    const alreadyIn = phaseElapsed >= durationIn;
    if (loaded && (inIndexOneAndAlreadyShow || alreadyIn)) {
      setIsVisible(false);
      setNeverVisibleAgain(true);
      const timeout = setTimeout(() => {
        navigate('/option/0', { replace: true });
      }, durationOut);
      return () => clearTimeout(timeout);
    }
    setIsVisible(phaseElapsed >= 0 && phaseElapsed < durationIn + durationShow);
    setText(loadingTexts[index]);

    setTick((tick) => tick + 1);
  }, 100);

  return (
    <PageWrapper>
      <div
        className={clsx(
          'fixed inset-0 flex items-center justify-center text-base transition-opacity',
          isVisible ? 'opacity-100' : 'opacity-0'
        )}
        style={{
          transitionDuration: isVisible ? `${durationIn}ms` : `${durationOut}ms`,
        }}
      >
        {text}
      </div>
    </PageWrapper>
  );
}
