import { create } from 'zustand/react';
import { persist, createJSONStorage } from 'zustand/middleware';

export type Step =
  | {
      reply?: string;
      newQuestion?: string;
      options?: string[];
      end?: false;
    }
  | {
      suggestionKeyword: string;
      suggestion: string;
      reason: string;
      style: string;
      quotes: {
        content: string;
        author: string;
      };
      end: true;
    };

export interface State {
  question: string;
  sessionId: string;
  steps: Step[];
  states: {
    selectedOptionIndex?: number;
    customAnswerContent: string;
  }[];
}

export const useGlobalStore = create(
  persist<State>(
    (set, get) => ({
      question: '',
      sessionId: '',
      steps: [],
      states: [],
    }),
    {
      name: 'coin-ai-storage',
      storage: createJSONStorage(() => sessionStorage),
    }
  )
);
