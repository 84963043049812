import clsx from 'clsx';
import { IoChevronBack } from 'react-icons/io5';
import { useViewportOffsetWhenUsingVirtualKeyboardInIos } from '../utils/useOffsetInIos';

export function TopBar({
  onLeftButtonClick,
  leftButtonIcon = <IoChevronBack className="w-6 h-6" />,
  bottomElement,
  transparent,
  backdropFilter,
  rightElement,
}: {
  onLeftButtonClick: () => void;
  leftButtonIcon?: React.ReactNode;
  bottomElement?: React.ReactNode;
  transparent?: boolean;
  backdropFilter?: boolean;
  rightElement?: React.ReactNode;
}) {
  const offsetInIos = useViewportOffsetWhenUsingVirtualKeyboardInIos();
  return (
    <>
      <div
        className={clsx(
          'sticky top-0 -mt-6 pt-6 z-10',
          !transparent && 'bg-[#FFFBF6]',
          backdropFilter && 'bg-opacity-70 backdrop-blur'
        )}
        style={{
          transform: `translateY(${offsetInIos}px)`,
        }}
      >
        <div className="flex items-center justify-between">
          <button
            onClick={() => {
              onLeftButtonClick();
            }}
          >
            {leftButtonIcon}
          </button>
          {rightElement}
        </div>
        {bottomElement}
      </div>
    </>
  );
}
