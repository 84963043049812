import { useInterval, useTimeout } from 'ahooks';
import clsx from 'clsx';
import { create } from 'mutative';
import { useCallback, useRef, useState } from 'react';
import { Route, Routes, useNavigate, useParams } from 'react-router-dom';
import { ArrayValues } from 'type-fest';
import { PageWrapper } from '../components/PageWrapper';
import { TopBar } from '../components/TopBar';
import { parseRawJsonFromLlmOutput } from '../utils/parse';
import { Step, useGlobalStore } from '../utils/store';
import { trpc } from '../utils/trpc';
import { useViewportOffsetWhenUsingVirtualKeyboardInIos } from '../utils/useOffsetInIos';

export function OptionPage() {
  const { stepNum: _stepNum = 0 } = useParams<'stepNum'>();

  return (
    <Routes>
      <Route path="/result/*" element={<ResultPage stepNum={Number(_stepNum)} key={_stepNum} />} />
      <Route path="/*" element={<Page stepNum={Number(_stepNum)} key={_stepNum} />} />
    </Routes>
  );
}

function Page({ stepNum }: { stepNum: number }) {
  const navigate = useNavigate();

  const [isOpenCustomAnswerInput, setIsOpenCustomAnswerInput] = useState(false);

  const inputDivRef = useRef<HTMLDivElement>(null);
  const customAnswerButtonRef = useRef<HTMLButtonElement>(null);
  const customAnswerInputRef = useRef<HTMLTextAreaElement>(null);
  // useClickAway(() => {
  //   setOpenCustomAnswerInput(false);
  // }, [inputDivRef, customAnswerButtonRef]);

  const steps = useGlobalStore((state) => state.steps);
  const states = useGlobalStore((state) => state.states);
  const { selectedOptionIndex = undefined, customAnswerContent = '' } = states[stepNum] ?? {};

  const setCurrentState = useCallback(
    (v: Partial<ArrayValues<typeof states>>) => {
      useGlobalStore.setState((state) =>
        create(state, (state) => {
          state.states = state.states.slice(0, stepNum + 1);
          state.states[stepNum] ??= { customAnswerContent: '' };
          state.states[stepNum] = { ...state.states[stepNum], ...v };
        })
      );
    },
    [stepNum]
  );
  const setSelectedOptionIndex = useCallback(
    (v: number | undefined) => {
      setCurrentState({ selectedOptionIndex: v });
    },
    [setCurrentState]
  );
  const setCustomAnswerContent = useCallback(
    (v: string) => {
      setCurrentState({ customAnswerContent: v });
    },
    [setCurrentState]
  );
  const openCustomAnswer = useCallback(() => {
    setIsOpenCustomAnswerInput(true);
    setSelectedOptionIndex(-1);
    customAnswerInputRef.current?.focus();
  }, [setSelectedOptionIndex, setIsOpenCustomAnswerInput]);

  const currentStep = steps.at(stepNum) as Step & { end: false };

  const selectOptionMutation = trpc.selectOptionStream.useMutation({
    onSuccess: (iterable) => {
      // prevent async code block onSuccess
      (async () => {
        let content = '';
        for await (const value of iterable) {
          content += value;
          if (!content.includes('{')) continue;
          // eslint-disable-next-line no-loop-func
          useGlobalStore.setState(({ steps }) => ({
            steps: create(steps, (steps) => {
              steps[stepNum + 1] = parseRawJsonFromLlmOutput(content);
            }),
          }));
        }
      })();
    },
    trpc: {
      context: {
        useStream: true,
      },
    },
  });
  const selectOption = useCallback(
    (v: string) => {
      useGlobalStore.setState(({ steps }) => ({
        steps: steps.slice(0, stepNum + 1),
      }));
      selectOptionMutation.mutate({
        stepNum: stepNum + 1,
        answer: v,
        sessionId: useGlobalStore.getState().sessionId,
      });
      navigate(`/option/${stepNum}/result`);
    },
    [navigate, selectOptionMutation, stepNum]
  );

  const offsetInIos = useViewportOffsetWhenUsingVirtualKeyboardInIos();
  return (
    <PageWrapper>
      <TopBar
        backdropFilter
        onLeftButtonClick={() => {
          navigate(-1);
        }}
      />

      <div className="flex flex-col items-center mt-7">
        <div className="mt-3 text-base">停下来，感受你的内心。</div>
        <div className={clsx('justify-center min-h-20 px-5 text-2xl font-bold text-center mt-9 transition-opacity')}>
          <div>{currentStep?.newQuestion}</div>
        </div>

        <div className={clsx('flex flex-col items-center mt-8 space-y-5')}>
          {currentStep?.options
            ?.filter((v) => !['其他', '其它'].includes(v))
            .slice(0, 3)
            .map((v, index) => (
              <button
                key={v}
                onClick={() => {
                  setIsOpenCustomAnswerInput(false);
                  setSelectedOptionIndex(index);
                  selectOption(v);
                }}
                className={clsx(
                  'rounded-[26px] text-black w-64 py-3 px-5 text-xl border border-black',
                  selectedOptionIndex === index && 'bg-[#FAECD7]'
                )}
              >
                {v}
              </button>
            ))}
          {isOpenCustomAnswerInput ? (
            <button
              ref={customAnswerButtonRef}
              onClick={openCustomAnswer}
              className="rounded-full text-black bg-[#FAECD7] w-64 h-[52px] text-xl border border-black border-dashed"
            >
              正在输入…
            </button>
          ) : customAnswerContent !== '' ? (
            <div className="flex flex-col items-center space-y-2">
              <button
                ref={customAnswerButtonRef}
                onClick={openCustomAnswer}
                className={clsx(
                  'rounded-[26px] text-black text-center  w-64 px-5 py-3.5 text-base border border-black border-dashed',
                  selectedOptionIndex === -1 && 'bg-[#FAECD7]'
                )}
              >
                <div className="line-clamp-2">{customAnswerContent}</div>
              </button>
              <div className="text-[#A09380] text-sm">再次点击修改</div>
            </div>
          ) : (
            <>
              {currentStep?.newQuestion && (
                <button
                  ref={customAnswerButtonRef}
                  onClick={openCustomAnswer}
                  className="rounded-full text-black w-64 h-[52px] text-xl border border-black border-dashed"
                >
                  输入其他回答…
                </button>
              )}
            </>
          )}
          {selectOptionMutation.isPending && (
            <div className={clsx('fixed bottom-24 text-lg transition-opacity')}>
              正在分析
              <AnimatedApostrophe />
            </div>
          )}
        </div>
        <div
          ref={inputDivRef}
          className={clsx(
            'fixed inset-x-0 mx-auto max-w-xl bottom-0 px-4 py-3 transition-opacity',
            isOpenCustomAnswerInput ? 'opacity-100' : 'opacity-0 pointer-events-none'
          )}
          style={{
            transform: `translateY(${-offsetInIos}px)`,
          }}
        >
          <div className="flex items-center w-full space-x-2">
            <textarea
              ref={customAnswerInputRef}
              value={customAnswerContent}
              onChange={(e) => {
                setCustomAnswerContent(e.target.value);
                e.target.style.height = '52px';
                e.target.style.height = e.target.scrollHeight + 'px';
              }}
              className="px-5 bg-white py-3 resize-none outline-none h-[52px] overflow-hidden bg-transparent grow text-base placeholder-[#CECECE] border border-black rounded-[26px]"
              placeholder="输入回答"
            />
            <button
              disabled={customAnswerContent === ''}
              className="disabled:bg-[#FAECD7] transition-colors bg-[#D4C2A7] rounded-full shrink-0 h-[52px] w-[52px] flex items-center justify-center"
              onClick={() => {
                setIsOpenCustomAnswerInput(false);
                selectOption(customAnswerContent);
              }}
            >
              <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.536 21.686C14.574 21.7807 14.6401 21.8615 14.7253 21.9175C14.8106 21.9736 14.9109 22.0022 15.0129 21.9996C15.1149 21.997 15.2136 21.9633 15.2959 21.9029C15.3781 21.8426 15.44 21.7585 15.473 21.662L21.973 2.662C22.0051 2.57339 22.0112 2.4775 21.9907 2.38555C21.9702 2.2936 21.9239 2.20939 21.8573 2.14277C21.7907 2.07616 21.7064 2.02989 21.6145 2.00939C21.5225 1.98888 21.4267 1.99499 21.338 2.027L2.33805 8.527C2.24155 8.56009 2.15747 8.62191 2.09712 8.70417C2.03677 8.78642 2.00302 8.88517 2.00041 8.98715C1.9978 9.08914 2.02644 9.18949 2.08251 9.27472C2.13857 9.35996 2.21937 9.426 2.31405 9.46399L10.2441 12.644C10.4947 12.7444 10.7225 12.8945 10.9136 13.0852C11.1047 13.276 11.2552 13.5035 11.356 13.754L14.536 21.686Z"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M21.8541 2.147L10.9141 13.086"
                  stroke="white"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}

export function AnimatedApostrophe() {
  const [tick, setTick] = useState(0);
  useInterval(() => {
    setTick((tick) => (tick + 1) % 4);
  }, 300);

  return (
    <>
      <span className={clsx([1].includes(tick) && 'opacity-0')}>.</span>
      <span className={clsx([1, 2].includes(tick) && 'opacity-0')}>.</span>
      <span className={clsx([1, 2, 3].includes(tick) && 'opacity-0')}>.</span>
    </>
  );
}

function ResultPage({ stepNum }: { stepNum: number }) {
  const navigate = useNavigate();

  const steps = useGlobalStore((state) => state.steps);
  const nextStep = steps[stepNum + 1];

  const [showTip, setShowTip] = useState(!!nextStep);
  useTimeout(() => {
    setShowTip(true);
  }, 100);

  return (
    <PageWrapper>
      <TopBar
        backdropFilter
        onLeftButtonClick={() => {
          navigate(-1);
        }}
      />

      <div className="flex flex-col items-center mt-7">
        <div
          className={clsx(
            'mt-3 text-base transition-opacity duration-1000',
            showTip ? 'opacity-100' : 'opacity-0 pointer-events-none'
          )}
        >
          {stepNum === 5 ? '别着急，旅程已过半。' : '深呼吸，我听到了你。'}
        </div>

        <div className={clsx('flex flex-col items-center mt-8 space-y-5')}>
          <div className={clsx('!mt-24 flex items-center flex-col space-y-7 duration-1000 transition-opacity')}>
            {nextStep?.end ? (
              <button
                onClick={() => {
                  navigate('/result');
                }}
                className={clsx(
                  'rounded-full text-white bg-[#A09380] font-bold w-64 h-[52px] text-xl border border-black transition-opacity duration-1000',
                  (nextStep as Step & { end: true })?.quotes?.author ? 'opacity-100' : 'opacity-0 pointer-events-none'
                )}
              >
                获得总结
              </button>
            ) : (
              <>
                <div className="fixed px-[30px] text-base text-center top-72">
                  <div className="text-2xl">{(nextStep as Step & { end: false })?.reply}</div>
                  <button
                    onClick={() => {
                      navigate('/option/' + (stepNum + 1));
                    }}
                    className={clsx(
                      'rounded-full text-white bg-[#A09380] mt-32 font-bold w-64 h-[52px] duration-1000 transition-opacity text-xl border border-black',
                      (nextStep as Step & { end: false })?.newQuestion ? 'opacity-100' : 'opacity-0 pointer-events-none'
                    )}
                  >
                    下一题
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </PageWrapper>
  );
}
