import { useObservableState } from 'observable-hooks';
import { fromEvent, filter, map } from 'rxjs';

const originHeight = window.visualViewport!.height;
const visualViewportResize$ = fromEvent(window.visualViewport!, 'resize');
const viewportOffsetWhenUsingVirtualKeyboardInIos$ = visualViewportResize$.pipe(
  filter(() => /iPhone|iPad|iPod/.test(navigator.userAgent)),
  map(() => Math.max(originHeight - window.visualViewport!.height, 0))
);

export function useViewportOffsetWhenUsingVirtualKeyboardInIos() {
  return useObservableState(viewportOffsetWhenUsingVirtualKeyboardInIos$, 0);
}
