import { IoChevronBack } from 'react-icons/io5';
import { PageWrapper } from '../components/PageWrapper';
import { useNavigate } from 'react-router-dom';
import { CoinVector } from '../components/CoinVector';
import { useGlobalStore } from '../utils/store';

export function ConfirmPage() {
  const navigate = useNavigate();

  const question = useGlobalStore((state) => state.question);
  return (
    <PageWrapper>
      <button
        onClick={() => {
          navigate(-1);
        }}
      >
        <IoChevronBack className="w-6 h-6" />
      </button>

      <div className="flex flex-col items-center mt-7">
        <div className="justify-center text-2xl font-bold">这是你现在的困惑吗？</div>
        <div className="mt-2.5 text-base">确认后，我们一起慢慢解开它。</div>
        <div className="flex space-x-3">
          <CoinVector hideDashedLine className="mt-10" outerDiameter={273} innerDiameter={273}>
            <div className="text-2xl font-bold text-center">{question}</div>
          </CoinVector>
        </div>
        <div className="flex flex-col items-center mt-[30px] space-y-3">
          <button
            onClick={() => {
              navigate('/option');
            }}
            className="rounded-full text-white bg-[#A09380] font-bold w-64 h-[52px] text-xl border border-black"
          >
            是的
          </button>
          <button
            onClick={() => {
              navigate('/ask');
            }}
            className="rounded-full text-black w-64 h-[52px] text-xl border border-black"
          >
            修改输入
          </button>
        </div>
        {/* <div>十道问题，解开困惑</div> */}
      </div>
    </PageWrapper>
  );
}
